<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title"><i class="far fa-exchange text-primary"></i>{{$t('transfer.1')}}</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->
			<form name="withfrm">
				<div class="s_left">
					<div class="withdrawal01" >
						<p class="mypage_section01_t">{{$t('transfer.2')}}</p>
						<div class="mypage_input_box">
							<table style="width:100%;">
								<tbody>
									<tr>
										<td>{{$t('transfer.3')}}</td>
										<td>
											<select name="coin_type" v-model='coin' class="withdrawal_input01" @change="ChangeCoin()">
												<option value="">{{$t('transfer.4')}}</option>
												<option v-for="(item,index) in coin_list" :key="index" :value="item.coin">{{ item.name }}</option>
											</select>
										</td>
									</tr>
									<tr v-if="coin !=''">
										<td>{{main}} {{$t('transfer.5')}}</td>
										<td>
											<input type="text" name="mycash" placeholder="" class="withdrawal_input01" v-model="main_balance" disabled>
										</td>
									</tr>
									<tr v-if="coin !=''">
										<td>{{coin}} {{$t('transfer.5')}}</td>
										<td>
											<input type="text" name="mycash" placeholder="" class="withdrawal_input01" v-model="balance" disabled>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="mypage_input_box" v-if="coin !=''">
							<table style="width:100%;">
								<tbody>
									<tr>
										<td>{{$t('transfer.6')}}</td>
										<td>
											<input type="number" class="withdrawal_input01" v-model="w_balance" name="amount" >
										</td>
									</tr>
									<!-- <tr>
										<td>{{$t('transfer.7')}}</td>
										<td>
											<input type="text" name="fpass" class="withdrawal_input01" v-model="otp_code">
										</td>
									</tr> -->
								</tbody>
							</table>
						</div>
						<!--withdrawal_input_box end-->
						<div style="text-align: center;" v-if="coin !=''">
							<input type="button" name="withok" class="withdrawal_botton w-100" :value="$t('transfer.8')" @click ="TransferPoint()">
						</div>
					</div>
				</div>
			</form>
			<!--s_left end-->
			<div class="s_right">
				<div class="withdrawal01">
					<p class="mypage_section01_t">{{$t('transfer.9')}}</p>
					<div class="notice_table04 px-0">
						<table style="width:100%" class="main_table02">
							<thead>
								<tr>
									<th>{{$t('transfer.10')}}</th>
									<th>{{$t('transfer.11')}}</th>
									<th>{{$t('transfer.13')}}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in list" :key="index">
									<td>{{item.symbol}} -> {{ item.target }}</td>
									<td>{{item.amount}}</td>
									<td>{{item.date}}</td>
								</tr>
							</tbody>
							<tbody></tbody>
						</table>
					</div>
				</div>
			</div>
			<!--s_left end-->
		</div>
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
import VueQrcode from 'vue-qrcode'

	export default {
		components: {
			VueQrcode,
		},
		data(){
			return{
				list:[
					
				],
				coin_list : [
					{coin : 'BSC-USD',name : 'USDT(BEP20)',net :'BNB'},
					{coin : 'USDT',name : 'USDT(TRC20)',net :'TRX'}
				],
				coin : '',
				main : '',
				net : '',
				main_balance : '',
				balance : '',
				w_balance : '',
				// otp_code : '',
			}
		},
	    mounted(){
			//this.CheckOTP();
			this.GetTransferList()
		},
		methods : {
			GetTransferList(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
				
				this.$http.post(`/member/transfer/GetTransferList`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.list = body.list

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			TransferPoint(){
				const balance = this.balance;
				const w_balance = this.w_balance;
				// const otp_code  = this.otp_code
				if(w_balance ==''){
					this.$alert(this.$t("transfer.14"))
					return false;
				}

				const a = exactMath.sub(balance,w_balance)

				if(a <0){
					this.$alert(this.$t("transfer.15"))
					return false;
				}

				// if(otp_code ==''){
				// 	this.$alert(this.$t("coin.20"))
				// 	return false;
				// }
				
				this.ConfirmTransfer();
			},
			ConfirmTransfer(){
				const coin = this.coin
				const w_balance = this.w_balance;
				// const otp_code = this.otp_code;
				const body = {coin,w_balance};
				// const body = {coin,w_balance,otp_code};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
				let loader = this.$loading.show({
					loader: 'dots',color:'#ff0073'
				});
				this.$http.post(`/member/transfer/ConfirmTransfer`,{req}).then(
					res => {
						if(res.status==200){
							loader.hide();
							if(res.data.code=='200'){
								this.$router.go()

							}else if(res.data.code=='300'){
								this.$alert(this.$t("coin.22"))

							}else if(res.data.code=='400'){
								this.$alert(this.$t("coin.17"))

							}else if(res.data.code=='100'){
								this.$alert(this.$t("coin.18"))

							}else if(res.data.code=='110'){
								this.$alert(this.$t("transfer.16"))

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetCoinBalance(){
				const coin = this.coin
				const body = {coin};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/transfer/GetCoinBalance`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								
								this.main_balance = body.main_balance;
								this.balance = body.balance;

								

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			CheckOTP(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/withdraw/CheckOTP`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.otp_able = body.otp_able;
								
								if(body.otp_able =='N'){
									this.$router.push({path:`/${this.$i18n.locale}/otp`})
								}

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			ChangeCoin(){
				const coin = this.coin;
				
				let main = '';
				
				let balance = '';
				let main_balance = '';
				let w_balance = '';
				for (const el of this.coin_list) {
					if(coin ==el.coin){
						
						main = el.net;
						main_balance ='';
						balance = '';
						w_balance = '';
					}
					
				}

				this.main = main;
				this.main_balance = main_balance;
				this.balance = balance;
				this.w_balance = w_balance;

				this.GetCoinBalance();
			},
			
			checkStatus : function(status){
				if(status =='H'){
					return this.$t('transaction.23')
				}else if(status =='Y'){
					return this.$t('transaction.24')
				}else if(status =='C'){
					return this.$t('transaction.25')
				}

			}
		}
	}
</script>
<style scoped>
.notice_table04{
	box-shadow: none !important;
}
</style>