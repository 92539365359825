<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title"><i class="far fa-sack-dollar text-primary"></i>{{$t('withdraw_coin.1')}}</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->
			<div class="s_left">
				<div class="withdrawal01">
					<!--withdrawal_section01-->
					<p class="mypage_section01_t">{{$t('withdraw_coin.2')}}</p>
					<div class="mypage_input_box">
						<!--withdrawal_input_box-->
						<div name="profilefrm" method="post">
							<table style="width:100%;">
								<tbody>
									<tr>
										<td>{{$t('withdraw_coin.3')}}</td>
										<td>
											<select class="withdrawal_input01" v-model='coin' @change="CheckCoinBalance()">
												<option value='' style='display: none'>{{$t('withdraw_coin.4')}}</option>
												<option :value='item.coin' style='' v-for="(item,index) in coin_list" :key="index">{{item.name}}</option>
											</select>
										</td>										
									</tr>
									<tr>
										<td>{{$t('withdraw_coin.5')}}</td>
										<td>
											<input type="text" name="addres"  class="withdrawal_input01" :value="balance" readonly="">
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<!--withdrawal_input_box end-->
				</div>

				<div class="withdrawal01" style="margin-top:30px;"  v-if="otp_able =='Y'">
					<!--withdrawal_section01-->
					<p class="mypage_section01_t">{{$t('withdraw_coin.6')}}</p>
					<div class="mypage_input_box">
						<!--withdrawal_input_box-->
						<div name="profilefrm" method="post">
							<table style="width:100%;">
								<tbody>
									<tr>
										<td>{{$t('withdraw_coin.7')}}</td>
										<td>
											<input type="text" name="addres" class="withdrawal_input01" v-model="w_balance" ref="balanceRef"  autocomplete='off'>
										</td>
									</tr>
									<tr>
										<td>{{$t('withdraw_coin.8')}}</td>
										<td>
											<input type="text" name="addres" class="withdrawal_input01" v-model="to" ref="addressRef" autocomplete='off'>
										</td>										
									</tr>
									<tr>
										<td>{{$t('withdraw_coin.9')}}</td>
										<td>
											<input type="text" name="addres" class="withdrawal_input01" v-model="auth_code" ref="addressRef" autocomplete='off'>
										</td>										
									</tr>
								</tbody>
							</table>
						</div>
						<div style="text-align: center;">
							<input type="button" name="withok" class="withdrawal_botton" :value="$t('withdraw_coin.10')" @click ="Withdraw()">
						</div>
					</div>
					<!--withdrawal_input_box end-->
				</div>

				<!--withdrawal_section01 end-->
			</div>
		</div>
		
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
import VueQrcode from 'vue-qrcode'

	export default {
		components: {
			VueQrcode,
		},
		data(){
			return{
				list :[],
				qr_url : '',
				balance : '',
				otp_able : '',
				otp : 't',
				otp_code : '',
				auth_code : '',
				w_balance:'',
				fee : '',
				min_withdraw : '',
				fee_balance :'',
				total_balance : '',
				to:'',
				coin : '',
				coin_list : [
					{coin : 'BNB',name : 'BNB'},
					{coin : 'TRX',name : 'TRX'},
					{coin : 'BSC-USD',name : 'USDT(BSC20)'},
					{coin : 'USDT',name : 'USDT(TRC20)'}
				],
				min : '',
			}
		},
		mounted () {
			this.CheckOTP();
			
		},
		methods : {
			CheckCoinBalance(){
				const coin = this.coin;
				const body = {coin};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/withdraw/CheckCoinBalance`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.balance = body.balance;
								this.address = body.w_balance;

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			Withdraw(){
				const balance = this.balance;
				const w_balance = this.w_balance;
				const auth_code = this.auth_code;
				const to = this.to;
				const coin = this.coin

				if(w_balance ==''){
					this.$alert(this.$t('withdraw_coin.11'))
					return false;
				}

				if(balance ==''){
					this.$alert(this.$t('withdraw_coin.15'))
					return false;
				}
				const c_balance = exactMath.sub(balance,w_balance);

				if(c_balance <0){
					this.$alert(this.$t('withdraw_coin.12'))
					return false;
				}

				if(coin ==''){
					this.$alert(this.$t('withdraw_coin.13'))
					return false;
				}

				if(to ==''){
					this.$alert(this.$t('withdraw_coin.14'))
					return false;
				}

				if(auth_code ==''){
					this.$alert(this.$t('withdraw_point.23'))
					return false;
				}

				const min = this.min;

				const m_check = exactMath.sub(w_balance,min);

				if(m_check < 0){
					this.$alert(this.$t('withdraw_point.32'))
					return false;
				}

				const body = {w_balance,auth_code,to,coin};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/withdraw/ConfirmWithdraw`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								this.$alert(this.$t('withdraw_point.24')).then(
									()=>{
										this.$router.go();
									}
								)

							}else if(res.data.code=='100'){
								this.$alert(this.$t('withdraw_point.25'))
							}else if(res.data.code=='110'){
								this.$alert(this.$t('withdraw_point.26'))
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetWithdrawHistory(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/withdraw/GetWithdrawHistory`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.list = body.list;

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			
			GetBalance(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/withdraw/GetBalance`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.balance = body.balance;

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetWithdrawFee(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/withdraw/GetWithdrawFee`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.fee = body.fee;
								this.min = body.min;

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			CheckOTP(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/withdraw/CheckOTP`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.otp_able = body.otp_able;
								if(body.otp_able =='N'){
									this.$router.push({path:`/${this.$i18n.locale}/otp`})
								}
								

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			ChangeWithdrawBalance(){
				const balance = this.w_balance;
				const fee = this.fee;
				const percent = exactMath.div(fee,100);
				const fee_balance = exactMath.mul(balance,percent);
				this.fee_balance = fee_balance;
				const total = exactMath.sub(balance,fee_balance);
				this.total_balance = total;
			}
		},
		
	}
</script>
<style scoped>
@media screen and (max-width: 1025px){
	.notice_table04{
		overflow: initial;
		overflow-x: auto !important;
	}	
	.notice_table04 th,.notice_table04 td{
		min-width: 50px
	}
}

 .statusH {
	background-color: #72bb74;
    padding: 5px;
    border-radius: 10px;
    color: white;
 }

 .statusY {
	background-color: #4a6ef1;
    padding: 5px;
    border-radius: 10px;
    color: white;
 }

 .statusC {
	background-color: #fb5d5d;
    padding: 5px;
    border-radius: 10px;
    color: white;
 }
</style>