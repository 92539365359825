<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title"><i class="far fa-sack-dollar text-primary"></i>{{$t('withdraw_point.1')}}</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->
			<div class="s_left">
				<div class="withdrawal01">
					<!--withdrawal_section01-->
					<p class="mypage_section01_t">{{$t('withdraw_point.2')}}</p>
					<div class="mypage_input_box">
						<!--withdrawal_input_box-->
						<div name="profilefrm" method="post">
							<table style="width:100%;">
								<tbody>
									<tr>
										<td>{{$t('withdraw_point.3')}}</td>
										<td>
											<input type="text" name="addres"  class="withdrawal_input01" :value="balance" readonly="">
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<!--withdrawal_input_box end-->
				</div>

				<div class="withdrawal01" style="margin-top:30px;"  v-if="otp_able =='Y'">
					<!--withdrawal_section01-->
					<p class="mypage_section01_t">{{$t('withdraw_point.4')}}</p>
					<div class="mypage_input_box">
						<!--withdrawal_input_box-->
						<div name="profilefrm" method="post">
							<table style="width:100%;">
								<tbody>
									<tr>
										<td>{{$t('withdraw_point.5')}}</td>
										<td>
											<input type="text" name="addres" class="withdrawal_input01" v-model="w_balance" ref="balanceRef"  autocomplete='off' @keyup="ChangeWithdrawBalance()">
										</td>
									</tr>
									<tr>
										<td>{{$t('withdraw_point.6')}} <span>({{fee}}%)</span></td>
										<td>
											<input type="text" name="addres" class="withdrawal_input01" v-model="fee_balance" readonly>
										</td>
									</tr>
									<tr>
										<td>{{$t('withdraw_point.7')}}</td>
										<td>
											<input type="text" name="addres" class="withdrawal_input01" v-model="total_balance" readonly>
										</td>
									</tr>
									<tr>
										<td>{{$t('withdraw_point.8')}}</td>
										<td>
											<select class="withdrawal_input01" v-model='coin' @change="ChangeCoin()">
												<option value='' style='display: none'>{{$t('withdraw_point.9')}}</option>
												<option :value='item.coin' style='' v-for="(item,index) in coin_list" :key="index">{{item.name}}</option>
											</select>
										</td>										
									</tr>
									<tr>
										<td>{{$t('withdraw_point.10')}}</td>
										<td>
											<input type="text" name="addres" class="withdrawal_input01" v-model="to" ref="addressRef" autocomplete='off' disabled>
										</td>										
									</tr>
									<tr>
										<td>OTP {{$t('withdraw_point.14')}}</td>
										<td>
											<input type="text" name="addres" class="withdrawal_input01" v-model="auth_code" ref="addressRef" autocomplete='off'>
										</td>										
									</tr>
								</tbody>
							</table>
						</div>
						<div style="text-align: center;">
							<input type="button" name="withok" class="withdrawal_botton" :value="$t('withdraw_point.11')" @click="Withdraw()">
						</div>
					</div>
					<!--withdrawal_input_box end-->
				</div>

				<div class="withdrawal01" style="margin-top:30px;" v-if="otp_able =='N'">
					<!--withdrawal_section01-->
					<p class="mypage_section01_t">{{$t('withdraw_point.12')}}</p>
					<div class="mypage_input_box">
						<!--withdrawal_input_box-->
						<div name="profilefrm" method="post">
							<table style="width:100%;">
								<tbody>
									<tr>
										<td></td>
										<td style="text-align: center;">
											<vue-qrcode :value="qr_url" style='width:150px;margin:auto;'/>
										</td>
									</tr>
									<tr>
										<td>{{$t('withdraw_point.13')}}</td>
										<td>
											<input type="text" name="addres" id="recaddr" class="withdrawal_input01" v-model="otp" ref="balanceRef"  autocomplete='off' readonly onclick="copy_to_clipboard()">
										</td>
									</tr>
									<tr>
										<td>{{$t('withdraw_point.14')}}</td>
										<td>
											<input type="text" name="addres" class="withdrawal_input01" v-model="otp_code"  autocomplete='off'>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div style="text-align: center;">
							<input type="button" name="withok" class="withdrawal_botton" :value="$t('withdraw_point.15')" @click ="OtpAuth()">
						</div>
					</div>
					<!--withdrawal_input_box end-->
				</div>
				<!--withdrawal_section01 end-->
			</div>
			<div class="s_right">
				<div class="withdrawal01">
					<p class="mypage_section01_t">{{$t('withdraw_point.27')}}</p>
					<div class="notice_table04 shadow-none mt-3">
						<table style="width:100%" class="main_table02">
							<thead>
								<tr>
									<th>{{$t('withdraw_point.28')}}</th>
									<th>{{$t('withdraw_point.29')}}</th>
									<th>{{$t('withdraw_point.30')}}</th>
									<th>{{$t('withdraw_point.31')}}</th>
									<!-- <th>Txhash</th> -->
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in list" :key="index">
									<td>{{item.to}}</td>
									<td>{{item.coin}}</td>
									<td>{{item.w_balance}}</td>
									<td><span :class="`status${item.status}`">{{CheckStatus(item.status)}}</span></td>
									<!-- <td v-if="item.status == 'Y'">
										<a :href="`https://etherscan.io/tx/${item.txHash}`" target="_blank"><i class="fas fa-edit text-info font-16" style="color: #999"></i></a>
									</td>
									<td v-else></td> -->
								</tr>
							</tbody>
							<tbody></tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
import VueQrcode from 'vue-qrcode'

	export default {
		components: {
			VueQrcode,
		},
		data(){
			return{
				list :[],
				qr_url : '',
				balance : '',
				otp_able : '',
				otp : 't',
				otp_code : '',
				auth_code : '',
				w_balance:'',
				fee : '',
				min_withdraw : '',
				fee_balance :'',
				total_balance : '',
				to:'',
				coin : '',
				coin_list : [
					{coin : 'BSC-USD',name : 'USDT(BEP20)'},
					{coin : 'USDT',name : 'USDT(TRC20)'}
				],
				min : '',
			}
		},
		mounted () {
			this.GetWithdrawFee();
			this.GetWithdrawHistory();
			this.GetBalance();
			this.CheckOTP();
			
		},
		methods : {
			CheckStatus(status){
				if(status =='H'){
					return this.$t("withdraw_point.33")
				}else if(status =='Y'){
					return this.$t("withdraw_point.34")
				}else if(status =='C'){
					return this.$t("withdraw_point.35")
				}

			},
			Withdraw(){
				const balance = this.balance;
				const w_balance = this.w_balance;
				const auth_code = this.auth_code;
				const to = this.to;
				const coin = this.coin

				if(w_balance ==''){
					this.$alert(this.$t('withdraw_point.19'))
					return false;
				}

				const c_balance = exactMath.sub(balance,w_balance);

				if(c_balance <0){
					this.$alert(this.$t('withdraw_point.20'))
					return false;
				}

				if(coin ==''){
					this.$alert(this.$t('withdraw_point.21'))
					return false;
				}

				if(to ==''){
					this.$alert(this.$t('withdraw_point.22'))
					return false;
				}

				if(auth_code ==''){
					this.$alert(this.$t('withdraw_point.23'))
					return false;
				}

				const min = this.min;

				const m_check = exactMath.sub(w_balance,min);

				if(m_check < 0){
					this.$alert(this.$t('withdraw_point.32'))
					return false;
				}

				const body = {w_balance,auth_code,to,coin};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/withdraw/ConfirmWithdraw`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								this.$alert(this.$t('withdraw_point.24')).then(
									()=>{
										this.$router.go();
									}
								)

							}else if(res.data.code=='100'){
								this.$alert(this.$t('withdraw_point.25'))
							}else if(res.data.code=='110'){
								this.$alert(this.$t('withdraw_point.26'))
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetWithdrawHistory(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/withdraw/GetWithdrawHistory`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.list = body.list;

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			
			GetBalance(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/withdraw/GetBalance`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.balance = body.balance;
								this.bnb = body.bnb;
								this.trx = body.trx;
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetWithdrawFee(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/withdraw/GetWithdrawFee`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.fee = body.fee;
								this.min = body.min;

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			CheckOTP(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/withdraw/CheckOTP`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.otp_able = body.otp_able;
								if(body.otp_able =='N'){
									this.$router.push({path:`/${this.$i18n.locale}/otp`})
								}
								

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			ChangeWithdrawBalance(){
				const balance = this.w_balance;
				const fee = this.fee;
				const percent = exactMath.div(fee,100);
				const fee_balance = exactMath.mul(balance,percent);
				this.fee_balance = fee_balance;
				const total = exactMath.sub(balance,fee_balance);
				this.total_balance = total;
				
			},
			ChangeCoin(){
				if(this.coin =='BSC-USD'){
					this.to = this.bnb;
				}else if(this.coin =='USDT'){
					this.to = this.trx;
				}
				// this.to = 
			}
		},
		
	}
</script>
<style scoped>
@media screen and (max-width: 1025px){
	.notice_table04{
		overflow: initial;
		overflow-x: auto !important;
	}	
	.notice_table04 th,.notice_table04 td{
		min-width: 50px
	}
}

 .statusH {
	background-color: #72bb74;
    padding: 5px;
    border-radius: 10px;
    color: white;
 }

 .statusY {
	background-color: #4a6ef1;
    padding: 5px;
    border-radius: 10px;
    color: white;
 }

 .statusC {
	background-color: #fb5d5d;
    padding: 5px;
    border-radius: 10px;
    color: white;
 }
</style>